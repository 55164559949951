<template>
  <!-- FLEX CONTAINER of ITEMS -->
  <div class="_content-media-list m-2">
    <!-- ITEM's flex configuration -->
    <div :class="['_content-media-item', actionOnClick ? 'active-border cursor-pointer' : '']" :key="position" v-for="(media, position) in mediasFormatted">

      <!-- ITEM -->
      <div class="_content-media" @click="clickedMedia(media)">
        <slot name="prepend" :media="media">
        </slot>
        <div class="_content-media-icon">
          <div v-if="hasThumbnail(media)" class="_content-media-thumbnail">
            <img class="w-full h-full" :src="getMediaThumbnailUrl(media)"/>
          </div>
          <!-- <feather-icon class="w-full h-full p-4 font-normal" icon="FileIcon" svgClasses="stroke-1 h-full w-full"/> -->
          <div v-else class="w-full h-full flex items-center justify-center">
            <font-awesome-icon
              icon="file"
              class="h-8 w-8 m-auto"
            />
          </div>
        </div>
        <div class="_content-media-text">
          <slot name="title" :media="media">
            <div class="_content-media-title break-words">
              {{ getMediaTitle(media) }}
            </div>
          </slot>
          <div class="_content-media-subtitle">
            {{ getMediaSubtitle(media) }}
          </div>
        </div>
        <div class="_content-media-action">
          <div class="w-full h-full flex items-center justify-center pr-1 mr-5">
            <font-awesome-icon
              v-tooltip="'Visualizar'"
              @click="() => actionOnClick ? undefined : mediaAction(media, position)"
              :icon="getMediaActionIcon(media)"
              class="h-5 w-5 m-auto hover:text-success mr-5"/>

              <font-awesome-icon
                v-tooltip="'Excluir'"
                v-if="showDelete"
                @click="confirmOnDelete(media)"
                icon="times"
                class="h-5 w-5 m-auto hover:text-danger mr-5"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { humanFileSize } from '@/util/String'
import MediaService from '@/services/api/MediaService'

export default {
  components: {  },
  data: () => ({
    hovering: false,
    previewService: null,
    mediasFormatted: [],
    expandedMedias: [],
    mediaService: null,
  }),
  props: {
    actionOnClick: {
      type: Boolean,
      default: false
    },
    medias: {
      type: Array,
      default: () => []
    },
    showDelete: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.mediaService = MediaService.build(this.$vs)
    if (this._.isArray(this.medias) && this.medias.length > 0) {
      this.setFormattedMedias(this.medias)
    }
  },
  watch: {
    medias(val) {
      if (this._.isArray(val)) {
        this.setFormattedMedias(val)
      }
    }
  },
  methods: {
    setFormattedMedias(val) {
      this.mediasFormatted = val.map((media) => {
        return {
          ...media
        }
      })
    },
    clickedMedia(media) {
      if (this.actionOnClick) {
        this.mediaAction(media)
      }
    },
    // Different types of media are located in different places.
    getMediaSource(media) {
      return _.get(media, 'url');
    },
    // We get the proper Icon for the proper Action
    getMediaActionIcon(media) {
      return 'download'
    },
    // Some medias can only be downloaded, whilst others viewed and downloaded.
    mediaAction(media) {
      const id = _.get(media, 'id')
      const filename = this.getMediaTitle(media)
      const mimeType = this.getMediaType(media)
      this.mediaService.downloadMedia(id, filename, mimeType).then(
        (success) => {
          this.notifySuccess(this.$vs, 'Iniciando Download...')
        },
        (error) => {
          this.notifyError(this.$vs, 'Erro! Não foi possível iniciar o download deste arquivo.')
        }
      )
    },
    confirmOnDelete(media) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: 'Confirmação',
        acceptText: 'Sim',
        cancelText: 'Não',
        text: 'Tem certeza que deseja excluir?',
        accept: () => {
          this.$emit('deleteMedia', media)
          this.$emit('delete-media', media)
        }
      })
    },
    getMediaThumbnailUrl(media) {
      const defaultVal = 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Globe_icon.svg/64px-Globe_icon.svg.png'
      return _.get(media, 'thumbnail_url', defaultVal) || defaultVal
    },
    getMediaTitle(media) {
      return _.get(media, 'file_name')
    },
    getMediaSubtitle(media) {
      const size = humanFileSize(_.get(media, 'size', 0) || 0, true)
      return `Tamanho - ${size}`
    },
    getMediaType(media) {
      return _.get(media, 'mime_type')
    },
    hasThumbnail(media) {
      return false
    }
  }
}
</script>

<style lang="scss">
// Color palettes

div._content-media-list {
  @apply flex;
  @apply flex-col;
  @apply gap-2;
  @apply pb-6;
}

._content-media-item {
  border-width: 1px;
  border-radius: 0.375rem;
}

._content-media-text {
  border-width: 0px 0px 0px 2px;
  padding: 0.5rem;
  width: 100%;
}

._content-media-expansion {
  border-width: 1px 0px 0px 0px;
  & img {
    max-height: 500px;
  }
}

._content-media-item,
._content-media-text,
._content-media-expansion
{
  min-height: 50px;
  border-style: solid;
  border-color: theme('colors.grey-light');
}

._content-media-item,
._content-media-text,
._content-media-expansion {
&.active-border:hover {
     border-color: $primary;
   }
}

._content-media-icon {
  height: 5rem;
  min-width: 5rem;
}
._content-media-action {
  @apply ml-auto;
  min-width: 3rem;

  & > div.flex > * {
    @apply cursor-pointer;
  }
}

._content-media-title, ._content-media-subtitle {
  @apply select-none;
  display: block;
  height: 50%;
}

._content-media {
  @apply flex;
  @apply flex-row;
  width: 100%;
}

._content-media-thumbnail {
  & img {
    max-width: 5rem;
    max-height: 5rem;
    object-fit: contain;
  }
}
</style>
